<template>
  <sku :cycleType="2" compType="store-unpack-sku" />
</template>
<script>
import sku from '../../sku'
export default {
  components: {
    sku
  }
}
</script>
